import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Experience = () => {
  const frontExp = [
    { key: 'HTML', value: 'Experienced' },
    { key: 'CSS', value: 'Experienced' },
    { key: 'JavaScript', value: 'Experienced' },
    { key: 'Bootstrap', value: 'Experienced' },
    { key: 'jQuery', value: 'Experienced' },
    { key: 'React JS', value: 'Intermediate' },
  ];

  const backExp = [
    { key: '.NET', value: 'Experienced' },
    { key: 'C#', value: 'Experienced' },
    { key: 'SQL', value: 'Experienced' },
    { key: 'C/C++', value: 'Experienced' },
    { key: 'Java', value: 'Intermediate' },
    { key: 'Node JS', value: 'Intermediate' },
  ];

  const frontExpList = frontExp.map((exp) =>
    <article key={exp.key} className='experience_details'>
      <BsPatchCheckFill className='experience_details-icon' />
      <div>
        <h4>{exp.key}</h4>
        <small className='text-light'>{exp.value}</small>
      </div>
    </article>
  );

  const backExpList = backExp.map((exp) =>
    <article key={exp.key} className='experience_details'>
      <BsPatchCheckFill className='experience_details-icon' />
      <div>
        <h4>{exp.key}</h4>
        <small className='text-light'>{exp.value}</small>
      </div>
    </article>
  );

  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className='container experience_container'>
        <div className='experience_frontend'>
          <h3>Front-end Development</h3>
          <div className='experience_content'>
            {frontExpList}
          </div>
        </div>

        <div className='experience_backend'>
          <h3>Back-end Development</h3>
          <div className='experience_content'>
            {backExpList}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience